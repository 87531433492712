import {
    Field,
    Input as HeadlessUIInput,
    Label,
    InputProps as HeadlessUIInputProps,
} from "@headlessui/react";

function BaseInput({
    className,
    floatingPlaceholder = false,
    ...rest
}: HeadlessUIInputProps & {
    floatingPlaceholder?: boolean;
}) {
    return (
        <>
            <HeadlessUIInput
                className={`rounded-md shadow-xs border border-gray-300 focus:border-gray-300 focus:ring-3 focus:ring-gray-200/50 peer ${floatingPlaceholder && rest.placeholder ? "pt-2 placeholder-shown:pt-0" : ""} ${
                    className ?? ""
                }`}
                {...rest}
            />
            {rest.placeholder && floatingPlaceholder && (
                <Label className="absolute peer-placeholder-shown:hidden text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:rtl:translate-x-1/4 peer-focus:rtl:left-auto">
                    {rest.placeholder}
                </Label>
            )}
        </>
    );
}

interface InputProps extends HeadlessUIInputProps {
    Icon?: React.ReactNode;
    iconPosition?: "left" | "right";
    floatingPlaceholder?: boolean;
}
export default function Input({
    className,
    Icon,
    floatingPlaceholder = false,
    iconPosition = "left",
    ...rest
}: InputProps) {
    if (Icon) {
        return (
            <div className="relative mt-1 rounded-md">
                <div
                    className={`pointer-events-none absolute inset-y-0 ${iconPosition === "left" ? "left-0" : "right-4"} flex items-center pl-3`}
                >
                    {Icon}
                </div>
                <BaseInput
                    className={`${className} ${iconPosition === "left" ? "pl-10" : "pr-10 pl-4"}`}
                    {...rest}
                />
            </div>
        );
    }
    if (floatingPlaceholder) {
        return (
            <Field className="relative">
                <BaseInput
                    className={className}
                    floatingPlaceholder
                    {...rest}
                />
            </Field>
        );
    }
    return <BaseInput className={className} {...rest} />;
}
